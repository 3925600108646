<template>
    <div>

		<div class="title">
			<p class="main">Our Last Best Chance by King Abdullah II</p>
			<div class="subheadings">
				<p class="published-date">6th June 2024</p>
			</div>
		</div>
		<div class="article">
			<p>It will shock no one to learn that in this review of King Abdullah II’s memoir, which heavily features the conflict in Israel-Palestine, I am going to elect not to talk about it. Other than to say it was refreshing to read a non-hysterical account of the long term history of the conflict that attempts to take the participants, political or otherwise, at face value, and to treat their positions generously. This was mainly why I wanted to read Our Last Best Chance but I came away thankful that this is more of a memoir and less of a history lesson.</p>

			<p>It’s awkward to read a book that is hopeful for peace in Israel-Palestine from a 2024 perspective, but it’s also slightly encouraging. Abdullah’s book ends on an extremely optimistic note. We’re a long way from direct talks at the moment obviously, and we probably don’t have the global focus on resolution, rather than just a cessation of active war, that we might hope for, but even so. This book was published in 2011. If you’d asked me then if I thought we were close to a deal, I would have said no. Abdullah sees it differently. His position can be more or less summed up as “Whatever you think of the main actors on each side, everyone basically wants a lasting peace, and that means it can happen if everyone behaves seriously”.</p>

			<p>But that’s enough about today. A couple of things really stand out here. The first is the obvious one: This is a book written by a king. That is extremely peculiar even for a king who is very public in his pronouncements and politics. Nevertheless it is accessible and clearly written. It is obviously for a general audience and that makes it very digestible. Starting with a broad strokes history of Jordan and the Hashemite dynasty’s place in Islam, Abdullah goes on to cover his own life. His time at school in America, in the Jordanian army, in the special forces, as a diplomat, and then, somewhat unexpectedly, as king. For most of his adult life his uncle (who was subsequently arrested for allegedly planning a coup against him) was crown prince. This changed when Abdullah’s father, who had been a major figure in Middle Eastern politics and a serious global player, became terminal ill. Abdullah was at that point made the heir. It shows the sway Jordan has globally that the Israeli Prime Minister and Palestinian President were both present at his funeral.</p>

			<p>One frustrating thing is that Abdullah rarely admits to mistakes in the book. It’s a memoir so we only expect so much self-reflection. But given the political situation internally in Jordan has been mixed during his rule to put it mildly, it’s surprising that so little time is spent on his domestic affairs bar his work on encouraging investment, and his and his wife’s work on education in Jordan. The book is foreign policy focussed so perhaps I shouldn’t be too taken aback, but it is also supposed to be about his life and work. A large part of that is taken up by domestic Jordanian affairs. Jordanian readers are often critical that the book is so clearly written for a Western audience and I can understand that frustration.</p>

			<p>Abdullah gives extremely generous readings of almost every political figure he covers. From Barack Obama to George Bush to Saddam Hussein, he is keen to present their positions in the best possible light and empathise with why they took the actions they did. He seems to start from a place that no one person is ever really that evil and, while many people will not agree, I prefer my politicians to have at least a passing understanding of the motivations of those they disagree with. He quotes his father as saying “You negotiate peace with your enemies, not your friends” and that is worth remembering in times like these.</p>

			<p>In a few of these reviews I fear I’ve veered too far into “Here is everything the book says” and I don’t want to do that here because I won’t really do it justice and in summarising I’m likely to make errors so suffice it to say, Abdullah’s life appears at first to have an almost haphazard story. As mentioned he was not initially named his father’s heir and as a result he lives a relatively normal upper class life. He goes to a boarding school where he is just another student, he goes into the military and achieves a place at Sandhurst where he becomes an officer, and then becomes a respected soldier in the Jordanian army. It becomes clear as the narrative goes on that, in fact, his father probably did intend for him to become king all along and not having him as crown prince allowed him to have a separation from the palace work and become (to the extent that any prince can) his own man. Abdullah believes this stood him in good stead as he came to lots of things fresh upon his ascendance to the throne, but could also trade on the respect in which his father was held and the relationships he had.</p>

			<p>I feel like I’ve accidentally read quite a lot of quite military heavy non-fiction this year and I wasn’t really expecting that here. When we think of princes in the army in the UK we tend to think of King Charles bedecked in medals despite never having shot a gun in anger, or God forbid of Prince Andrew sailing around the Falklands with a bone dry brow. Abdullah doesn’t go too heavy on the military aspect though other to talk about his own rise and operations he was involved in which meant I had to wade through fewer battle descriptions than I’ve been used to. The focus of the book is the politics and ultimately that’s also where it’s strongest.</p>

			<p>Our Last Best Chance is basically worth reading. Yes, it’s out of date. Yes, the world is bleaker now than in 2011. But it’s still a refreshing perspective. An Arab nation that is at peace with Israel giving a telling of a decades long conflict from a generous point of view is a good thing to have read I think. Give it a go. And try to read Abdullah’s point of view with the open mind he has towards everyone else he describes.</p>

		</div>

    </div>
</template>


<script>

	export default {
		name: "OurLastBestChanceArticle"
	}

</script>


<style>

</style>