<template>
    <div>

		<div class="title">
			<p class="main">Noise: a flaw in human judgement by Daniel Kahneman, Olivier Sibony & Cass R. Sunstein</p>
			<div class="subheadings">
				<p class="published-date">21st July 2024</p>
			</div>
		</div>

		<div class="article">
			<p>I’m finally back on some kind of regularity with reading now, which is good because I have an absolute ton of stuff I want to read this year. It’s helped that summer seems to have sprung at last so a weekend in the garden with Noise by Daniel Kahneman, Olivier Sibony, and Cass Sunstein (and with a bit of a hangover) was on the cards. I liked both Nudge and Thinking Fast and Slow by the same authors, and while Noise is highly interesting, I found myself getting a bit frustrated by some of the conclusions that are drawn towards the end of the book.</p>

			<p>The basic premise of the book is summed up on literally the first page. Imagine shooters at a target. If they are all wrong in a similar way (i.e. too far to the left) this is bias, and bias is bad. But if they are all wrong in slightly different way, this is noise, and it’s also bad. The issue with noise is that it’s less easy to spot, and therefore more pernicious. And we pay less attention to it because we regard some level of variability between humans as inevitable.</p>

			<p>The authors attribute noise to basically three different things: Levels, patterns (or systems), and occasions. Level noise is probably the best named. It arises when people make different decisions at different levels because they interpret them differently. For example, some judges give harsher sentences just because they are harshed. Pattern noise is like a kind of personal bias. It’s repeatable and produces a difference with other similar decision makers. To continue with the sentencing example, pattern noise would be a judge who tends to be harsher on drug offences than driving ones. Occasion noise is what is left over and consists of those individual moments when a decision one makes differences from peers or themselves for some outside reason. Maybe they are hungry, or it’s an especially nice day. All of these have different causes and all contribute to overall levels of noise. The different sources are part of what makes noise hard to notice and eliminate.</p>

			<p>There are some good examples given of why noise is a bad thing. Sentencing for the same crime is not reliable between judges due to one being harsher than another; hiring decisions which are not based on the best candidate but on momentum of opinion in the room; medical diagnoses which don’t agree because one persons’ benign growth is another’s tumour. These are all real world scenarios where noise has a negative (or at least unfair) impact.</p>

			<p>Where the book starts to become difficult is when the authors turn to how noise should be reduced. It’s hard to disagree with the overall point. Noise is bad, humans are unreliable, and we need to take steps to reduce our variability to get better and more equitable outcomes. The issue is that a lot of the recommendations seem superficial, and while lip service is paid to the fact that reducing noise can be costly, the examples given are so extreme that it undercuts the validity of the point. Obviously 5 teachers shouldn’t grade the same essay to produce a less noisy average. That’s ridiculous, and no one reasonable would suggest it. But by giving these bad examples they seek to make also costly things seem more reasonable. Some of them are, but a lot aren’t.</p>

			<p>Averaging is great, but even in hiring decisions having multiple rounds of interview and multiple interviewers for each is very costly in person hours. Anonymising opinion is also good but when this is followed up by a discussion, it’s likely that momentum and influence will come into play anyway. Anchoring is helpful, but you need to have a good anchor, and so on. I promise this isn’t me being optimistic since my job is essentially making judgement calls. I do rely on a lot of the things suggested. When story pointing tickets for work, I do like to use averages. And I do like to use ‘poker’ where scores are revealed by each team member at the same time so one person can’t influence too much. But these are things that apply only in some situations and they’re useful when a decision is repeatable.</p>

			<p>For unrepeatable decisions, some really interesting points are made. I was especially interested in what the authors had to say about superforecasting. This is a phenomenon where some people are uncommonly good at predicting future events. Like, significantly better than their peers. The things that seem to influence these better predictions are not obvious, but they are learnable. Superforecasters pay close attention to the things that influence the decision - they don’t just wing it. They also consider base rates much more closely - they don’t take seemingly isolated things in actual isolation. I feel like these are actually some of the most important takeaways and, while they’re explained, they don’t make it into the ‘toolkit’ at the end on which more below. An example of this is to imagine you’re given a description of a candidate for CEO at company, and asked to predict whether they will still be in the job after 2 years. The person’s characteristics obviously matter here, but they matter when justifying moving away from the mean result you would expect, all things being equal. Do CEOs normally stay in jobs for over 2 years? Is the company broadly successful or does it change leadership often? Admittedly, this is a kind of anchoring, which is regarded as a noise reduction strategy, but it feels a bit easier and more applicable to me.</p>

			<p>The toolkit is the most frustrating part of the book by some distance and comes at the very end, leaving a bit of a sour taste. We get it - decisions should be process, rather than people, driven. Judgement is unreliable, so relying on rules, guidelines, and appropriate levels of anonymity and disclosure are hugely important. The issue is that this comes across as a bit ‘one-size-fits-all’ decision making when in reality each organisation is different and will need to do slightly different things. Making predictable decisions isn’t always a good thing either. Especially in circumstances where there isn’t a right answer which, in my professional experience, is most of the time. </p>

			<p>Consider for example, as the authors do but not in nearly sufficient detail in my opinion, tax law. It’s widely accepted that having overly prescriptive tax law, as both the UK and US have tended to do, creates a boom industry in loophole finding. Yes, we could try to list every eventuality so that everyone knows exactly what counts as a business expense in every situation but that would be tedious, time consuming, and still open to interpretation (see for example <a href="https://en.wikipedia.org/wiki/Jaffa_Cakes#Legal_status">The Jaffa Cake Incident</a>). It could be better, some argue, to simply allow “reasonable” expenses, and prohibit “wrongdoing”. Yes, this would need interpretation, but given that any attempt to avoid or evade tax could be regarded as “wrongdoing” (though courts have tended to think that avoiding is fine, but evading is not) this may actually lead to a simpler regime for most right thinking honest people. Imagine if the tax statute had to list the VAT status of every product for sale, and be updated every time something new came out? It’d be madness. It’d be less noisy, but it would be madness.</p>

			<p>I don’t want any of this to give the impression that Noise is not good, or not worth reading. It’s excellent, and it’s very valuable in giving insight into how we make mistakes. My only caveat would be that the authors seem to attribute all the worlds ills to a lack of decision matrices and, while I think matrices are great, I don’t think they can solve every problem. They will make things more predictable, and that’s often good, but it’s not everything.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "NoiseArticle"
	}

</script>


<style>

</style>