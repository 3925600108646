<template>
    <div>

		<div class="title">
			<p class="main">The Lion and the Unicorn by Richard Aldous</p>
			<div class="subheadings">
				<p class="published-date">17th May 2024</p>
			</div>
		</div>
		<div class="article">
			<p>This biography of Gladstone and Disraeli by Richard Aldous is a lesson in reading the subtitle of a book before departing.</p>

			<p>For years I’ve known that my political historical knowledge starts to taper off pre-War, becomes very patchy around the time of the First World War, and is almost non-existent by the time we get back to the Edwardian or Victorian eras. I obviously knew who Gladstone and Disreali were but very little else other than the fact they swapped jobs seemingly continuously for about 20 years and were two of the most able statesmen to ever live. That much is confirmed in Aldous’s book but his focus is much more on the personalities of the two than the actual political history. This was really disappointing to me because although I’m now much more aware of Gladstone’s sexual proclivities and Disraeli’s barefaced cheek about basically everyone, the actual politics is too often overlooked. Sentences like “Then the Third Reform Act was passed by Parliament” are common while huge extracts from personal diaries are recounted over and over.</p>

			<p>Clearly this is a work of serious history and is absolutely the right thing for some people. I did enjoy it, but it wasn’t what I expected. The story is more of a personal rivalry and reads like a drama more than a history. The two almost follow parallel heroes' journeys. Rising and falling with the success or failure of the other, inextricably linked. It reminded me a lot of the way the Hamilton/Burr rivalry is framed in the musical. Yes it’s political, but it’s a rivalry. In this way Aldous is successful in humanising the kinds of people we probably imagine as stern stuffy Victorian politicians. Gladstone especially has the image of the Vogon poet from Hitchhikers Guide, yet has sexual tastes totally contradictory to his public image. Disraeli is an almost Oscar Wilde figure, but also a deeply devoted husband whose public life is essentially ended by the impact of his wife’s death - a wife Aldous suggests he only took to enable his career in the first place.</p>

			<p>I feel a bit guilty about not having more to say about this book, but to be honest once I realised it wasn’t what I expected I probably took it in less critically than I normally would have. It’s not that I didn’t enjoy it, I just was not especially interested in the “he said she said” drama that Aldous focuses on. That’s my fault, not his. He is at pains to stress what the aim of the book is. I just didn’t listen.</p>
		</div>

    </div>
</template>


<script>

	export default {
		name: "TheLionAndTheUnicornArticle"
	}

</script>


<style>

</style>