<template>
    <div>

		<div class="title">
			<p class="main">Unreasonable Hospitality by Will Guidara</p>
			<div class="subheadings">
				<p class="published-date">23rd February 2024</p>
			</div>
		</div>

		<div class="article">
			<p>I got totally sucked in, as many of us did, by The Bear and Boiling Point over the last year or so. There is a scene in a great episode of season two of The Bear where a character is filled with inspiration by reading Unreasonable Hospitality by Will Guidara. I don’t run a restaurant, nor should I given I find hosting stressful and cooking difficult, but I thought I might take something from Guidara’s book nonetheless.</p>

			<p>Will Guidara is a very well known name in restauranter circles though I’ll admit I had never heard of him, or his Eleven Madison Park restaurant which, for a while, was rated the best in the world. He relays his upbringing which, crucially, involved parents also senior in the hospitality business. Though his mother’s serious illness and early death certainly cast a shadow on his early life. He trained in some very highly rated restaurants before attending the Cornell Culinary Institute (no, me neither) and coming out to take a job with the Union Square Hospitality Group. After a few head of front of house jobs, including setting up the cafe at the Museum of Modern Art, he was poached to re-launch Eleven Madison Park (EMP) with chef Daniel Humm - finally a name I recognised. The ‘plot’ in as much as there is one, tells the story of Guidara and Humm shepherding EMP from the 50th best restaurant to number one. That plot isn’t really the point though.</p>

			<p>The point is the titular unreasonable hospitality. If you’ve seen The Bear, you’ll be familiar with the episode Forks where a customer mentions to the person they’re dining with that they were disappointed to have missed out on a Chicago Deep Dish pizza. Having overheard this, the waiter rushes out to buy a deep dish and plates it for the table. This is inspired by a story from Guidara about a New York hot dog. These were referred to at EMP as “Legends” after a customer who called his treatment “Legendary”. The real heart of the book is these stories: Overhearing people talking about the tooth fairy? Put a quarter under their napkin secretly. See people coming in with suitcases? Give them a doggy bag of plane snacks. A couple comes in to get engaged? Have some Tiffany champagne flutes on hand that they can take away with them. These almost insane (or ‘unreasonable’) stories are what most people take away from this book and, yeah, they’re great. But the really important thing is how this can let you think about service and budgets.</p>

			<p>Guidara talks about the 95/5 rule of budgeting. Penny pinch on the 95% so you can spend 5% frivolously. In product, we refer to these frivolities as ‘Delighters’ in the Kano model. The classic example is to think about a car. The car needs an engine and seats, and increasing performance will attract more people. But everyone wants an absolutely banging stereo. You can add that to any car. A great example is Skoda having umbrellas in their doors. Such a daft little thing to add but you better believe I considered a Skoda for this reason. Guidara’s example is ice cream at MoMA. He spent a crazy amount of money on the spoons for the ice cream. We’re all familiar with the weird taste of wooden ice cream spoons. So spend a lot more to get a way better spoon and give people a great experience rather than a good one. This is the heart of unreasonable hospitality. If you can do totally over the top one offs then great. But providing great service by paying attention to small details and giving people surprisingly fun things will enhance anything.</p>

			<p>To be totally frank, a lot of the book is just good sense. Promote from within, give people ownership, focus on being what you want to be rather than avoiding what you don’t, a customer feeling they’ve had the wrong thing is what matters even if they’re incorrect (the example given being a customer asking for a medium steak and being served one, but thinking it was cooked rare and wanting it replaced). These are truisms that everyone should follow and a lot of the meat of the book is kind of trite ‘business lessons’ that anyone giving the subject more than 30 minutes thought could come up with. But there are little things that stand out. A principle EMP worked to was the One Inch Rule. The idea here is that you can cook and plate a perfect dish, but it doesn’t matter how well you do that if as the plate is put on the table it’s shaken and the presentation is ruined. Pay attention to the last inch and you will get the benefit of all the work you’ve put in before that last inch. I liked this way of thinking about getting things over the line. Like a sprinter, put your chest out and burst through the line - don’t collapse over it.</p>

			<p>The final thing I really picked up on here was the complete and utter failure of the new menu concept EMP tried after coming 3rd in the William Reed Best 50 awards. Throwing out their previous menu for a tasting menu with no choice, changing the serving style from a conversation to a much more heavy handed one - these were changes that earned EMP a scathing pre-review from the New York Times. Humm and Guidara dined at the restaurant themselves at this point and found they were interrupted 90 times during the 15(!) courses. This is not hospitality - this is being bothered. After the review, they cut the menu back and went back to first principles of hosting earning a retention of their 4 star NYT review, and first place in the Best 50 awards.</p>

			<p>Overall I enjoyed Unreasonable Hospitality, and I’m glad I read it. But I think if I didn’t have the context of the shows I discovered it from I would probably regard it as Yet Another Management Book and probably not worth my time. If you have any kind of interest in hospitality though it’s pretty interesting and light enough that it won’t take too much of your time. Final thought though? Books that put their key lessons in bold mid-paragraph could probably have been a TED talk of just those sentences. I’d probably have preferred that format.</p>
		</div>



    </div>
</template>


<script>

	export default {
		name: "UnreasonableHospitalityArticle"
	}

</script>


<style>

</style>