<template>
    <div>

		<div class="title">
			<p class="main">Phantom Plague by Vidya Krishnan</p>
			<div class="subheadings">
				<p class="published-date">28th February 2024</p>
			</div>
		</div>
		<div class="article">
			<p>If you think about tuberculosis, TB, consumption, you probably think “Victorian wasting disease” or “solved problem”. So sit down before I tell you that between March 2020 and June 2021 more people globally died of TB than of Covid-19. This is just one of the horror-movie level facts about the world’s rapidly deteriorating fight against one of the oldest diseases that I discovered from Phantom Plague by Vidya Krishnan. Every page produces a new gasp. If you’re not thinking about TB specifically, and antibiotic resistance more generally, you should be. And if you haven’t read Phantom Plague, it’s time to.</p>

			<p>Krishnan begins by talking about the history of germ theory and antibiotics generally. Pre-antibiotics, there were several magic theories and myths about infectious disease: That it was generated by the dead rising and infecting people; that it was caused by “miasma” or bad air; that it came “from the East”. We can see a lot of these theories reflected in the fiction of the day and specifically vampire myths. Things began to change (or should have) with Ignaz Semmelweis. Semmelweis noticed that in his hospital, the doctors who performed autopsies had significantly worse surgical outcomes than midwives who didn’t when delivering babies. Semmelweis suggested that there was some kind of bad particle (germs) originating in the dead body and being transferred to the healthy person giving birth. He asked that surgeons clean their hands after autopsies. In a time where surgeons routinely boasted of their blood soaked aprons, quipped “surgery successful, patient dead”, and erected signs reading “Prepare to meet thy God” above their theatres, there should have been more openness to ways to improve surgical outcomes. No, Semmeweis was branded insane, sacked, and eventually committed and (probably) murdered.</p>

			<p>Enter Joseph Lister of the Glasgow Royal Infirmary. A pioneer of antiseptic surgery, Lister began treating open fractures by adding a splint and dressing them rather than performing the then typical amputation. When he noted the hugely increased survival rates, the era of antiseptic surgery was born. Further pushed forward by Robert Koch (who identified mycobacterium tuberculosis as the cause of TB) and Louis Pasteur (of Pasteurisation, among countless other things), medical treatment entered the modern age encapsulated by the discovery of penicillin in 1928. Finally, the war against bacterial infection was being won.</p>

			<p>Not so fast though, as Krishnan next takes us to India where TB is back on the march - in a big way. This is where the central premise of the book begins to come out. If you die of TB today, you don’t really die of TB, you die of poverty. TB is the symptom, but the disease is medical inequality and a general lack of value given to lives in the global south and developing world. By way of example, we hear about Building 10 in the centre of Mumbai’s slum. Mainly inhabited by lower caste people, and Untouchables, Building 10 is one of a number of huge high rise ghetto-flats. The houses on the bottom receive no light. Waste is thrown from windows due to bad plumbing. Because of the flying sewage, those on bottom floors don’t open their windows. The flats are so small that, even if people wanted to, they can’t open windows because furniture is up against them. One resident remarked that “the old only come down dead”. This isn’t urban planning. It’s ghettoisation. And by ghettoising people we create a perfect environment for TB to spread, and create an uneven battlefield where medicine cannot win.</p>

			<p>“The spread of infectious diseases is dictated by human folly and prejudices'' summarises the central problem in Mumbai and India. Building 10 had 50 cases of extensively drug resistant TB within about 20 floors. This is like finding 50 cases of the same rare cancer in a single street. It doesn’t happen by accident. Drug resistant TB (DRTB) and extensively drug resistant TB (XDRTB) are some of the most horrifying things you can imagine. You might be familiar with your GP telling you they won’t give you antibiotics for a cold because it just increases resistance. But in reality, the problem is significantly larger than that, though you still shouldn’t take the antibiotics. Dr Zarir Udwaria is one of the heroes of this story (to the extent that devastating losses can have heroes) in part for his relentless fight against the misuse of antibiotics. Udwaria is not for rationing antibiotics - he wants them everywhere they are needed - but he is trying to fight bad use of them. In a test he ran, 100 actors were asked to present to doctors complaining of classic TB symptoms. Only 5% of doctors gave the right prescription. Many gave the wrong antibiotics, or not enough. In the case of a patient with DRTB this is a disaster. Not only will the drugs do nothing, they will increase the disease's ability to resist them further. This is where the catastrophe is unfolding.</p>

			<p>And it’s getting worse because of a return to the kind of magical thinking Krishnan outlines as preventing good treatment historically. Under the Modi government, there has been a turn on scientific thinking. A denial of true numbers of diagnoses, and a feeling that some people are not able to be treated because they can’t follow the regimen of pills, leads to patients being ignored. Krishnan tells the story of two young women mis-diagnosed and mistreated over the course of years. I’m not going to recount every in and out of their fight and legal battle here as the principle is really what matters, though the details are harrowing, upsetting, and infuriating. The fact that patients are routinely prescribed old medications known to cause deafness when others are available, but are being “protected for the future” is just one of the tragedies listed. These stories happen because we are making bad, prejudiced, and money-driven medical decisions.</p> 

			<p>We can’t go any further without talking about drug sensitivity tests, bedaquiline and delamanid. DSTs will tell a doctor if a patient has DRTB. If they do, they need to be treated with one of the only two drugs known to work against DRTB which are bedaquiline and delamanid. There is resistance in India to prescribing these drugs though. They are expensive and rare, and their misuse could lead to further drug resistance. But as Dr Udwaria points out, who are we saving them for? The reality is that our medical social order is saving them for when DRTB becomes a problem in wealthy countries. The thinking, conscious or not, goes that if we give these new antibiotics to people who don’t take them correctly we will reduce their effectiveness. So it’s probably best to not use them. Except not using them is a death sentence. The point bears repeating: people aren’t dying of TB, they’re dying of inequality in medical treatment.</p>

			<p>There is a possibly apocryphal tale when discussing this kind of decision making around AZT, the first and main antiretroviral drug used to treat HIV, in Africa. The story goes that an executive said AZT wouldn’t work in Africa because people don’t own wristwatches. They tell time with the sun and that is not exact enough to get the AZT drug timings correct. Putting to one side the obvious racism in that statement (seriously? No one in Africa has a clock?) there is a problem with cultural differences and resulting treatment. A simple one is that TB is seen as a disease of the unclean in many countries so people are sometimes reluctant to be diagnosed or to disclose their diagnosis. But to just throw up our hands and say it can’t be done isn’t a solution. And it’s not a significant enough number of people to outweigh the larger problems. There are other elements in the rough middle of the book where Krishnan takes points to extremes I find it hard to agree with. Starting by making a strong case against donations of medical care (they are on the whim of the donor; charity is used to make up for other harmful decisions) Krishnan ends up painting Bill Gates as a sort of Big Pharma stooge. I’m not sure this stands up and Dr Paul Farmer of Partners In Health who Krishnan quotes liberally, and interestingly, has worked closely with Gates for years.</p>

			<p>The point about Gates though, in fairness, is actually a way in to thinking about the largest problem we face in fighting TB today. Patent law. We can talk all we like about prescriptions and diagnoses, but if the drugs doctors need are not readily available they will, out of a desire to help, prescribe something more in hope than expectation. Now, they probably shouldn’t be doing that, but trying to stop bad prescriptions without making better drugs available isn’t really a solution. The patent law comes down to two things: Evergreening, and Section 301 notices. You may be familiar with evergreening where a company makes a small change to a product in order to re-patent it. A Section 301 notice is a preliminary action taken by the US Department of Commerce against a country it sees as breaching US held patents. It often leads to economic sanctions. The result is that companies, like Johnson & Johnson who make bedaquiline, hold a monopoly on the only meaningful drug we have to treat a crisis. If an Indian company were to break the patent, they would be served a Section 301 notice and the Indian economy would suffer. So we have Western companies, enforcing a Western concept of intellectual property, to hoard treatments needed in the East, and which (in a cruel twist of fate) are primarily manufactured in the East. Krishnan makes a strong case that this is the true moral failure, and that this patent trolling is what we must tackle to solve this problem holistically.</p>

			<p>Where does all this leave us? Well, it’s probably time to panic. Recent initiatives by the WHO have failed. Bedaquiline is not in enough hands. We keep prescribing kanamycin to TB patients causing profound deafness and increased resistance. The results of Dr Udwaria’s tests of doctor’s ability to spot TB are getting worse. Recent estimates suggest there are as many as 3m undiagnosed TB patients in India alone. There have been recent successes. Johnson & Johnson just weeks ago declined to evergreen their patent on bedaquiline. Other companies like Danaher are reducing the cost of the drug sensitivity tests. The infrastructure is changing. But basically, it’s pretty bad. Resistance is on the up and we’re not getting new treatments. There isn’t a good or happy conclusion here. Phantom Plague is a horror story, and a warning. Start paying attention.</p>

		</div>

    </div>
</template>


<script>

	export default {
		name: "PhantomPlagueArticle"
	}

</script>


<style>

</style>