<template>
    <div>

		<div class="title">
			<p class="main">Tomorrow And Tomorrow And Tommorow by Gabrielle Zevin</p>
			<div class="subheadings">
				<p class="published-date">7th September 2024</p>
			</div>
		</div>

		<div class="article">
			<p>I’ve just been on holiday and, not wishing to imitate my Grampa taking Betrand Russell’s <i>A History of Western Philosophy</i> with me as “a bit of light reading”, I decided to read Tomorrow and Tomorrow and Tomorrow by Gabrielle Zevin rather than Team of Rivals. The history of the Lincoln cabinet will be next up but I had a much more fun time with Tomorrow and Tomorrow and Tomorrow than Team of Rivals. So much so that I only watched one film across 19 hours of flight time.</p>

			<p>This is my first fiction review here and honestly it’s hard to do this without recapping the plot so suffice it to say that this is basically a story about friendship and life finding a way that is really sweet but also does speak to something interesting about creativity and what it means to communicate. Following the story of Sam and Sadie as they grapple with being game designers from the 90s to the 10s, the plot is light but significant. It’s not a thriller - it’s a meditation, and it’s excellent.</p>

			<p>A couple of things that didn’t quite work for me first of all. The thrust of the plot is a series of real or perceived breaches of trust. I found these to be contrived and basically sometimes out of character. These are supposed to be best friends yet the betrayals rely on one or the other not taking in good faith or at face value the actions of the other. Maybe I’m naive, but this isn’t my experience. With your friends, you try to read actions generously even when they’re annoying or seem malicious. I get that there needs to be tension somewhere but I felt these could have been better earned. Similarly, the ‘redemption’ sequences feel a bit unearned and require a bit more of suspension of disbelief than I found credible. One section towards the end in particular that takes place in-game was a really lovely idea but it just didn’t work for me. I’ve seen others say it’s their favourite part and I can see why that is but the section in question just didn’t land for me.</p>

			<p>The book is at its best not when it tries to be plot driven but when it focuses on The Big Terrible Things and how we deal with them. These can be major ,from witnessing the death of a stranger, to handling breakups from relationships good and bad, or trivial, like the change that comes with getting older and how your relationships with people just become different. The novel’s title comes from a scene in MacBeth where the titular character is reflecting on how change is creeping up on him and he is not confident or secure in the position he now finds himself. This is a recurring theme in Tomorrow and Tomorrow and Tomorrow. Characters and their relationships change, their work is suddenly not what they expected, or a change they instigated that felt positive actually falls flat.</p>

			<p>There is a brilliant sequence when Sam, who we find out early has a severely damaged foot from a childhood accident, is handling the developing pain in his leg and coming to think of himself as ‘disabled’. Other characters notice that he is intentionally and publicly using a cane where he used to hide this. Sam sees this as owning his identity. Others see it as an affectation. Similarly, Sadie, who is a woman in tech, struggles with her place and feeling like she doesn’t get the credit she deserves. Others sometimes see her as wanting all the praise and none of the criticism and blaming on sexism what is actually just legitimate disapproval of her work. The point though is that classic thing that we judge others by their outcomes and ourselves by our intentions. No one is really wrong or right in Tomorrow and Tomorrow and Tomorrow, but Sam’s struggle with his disability and Sadie with her place in a male dominated world (as if there is any other kind…) do suggest that we should be kinder to others in our judgement of them.</p>

			<p>The most interesting part in terms of writing, and the most interesting part overall are different to me. At one point, we hear the internal monologue of a character who is seriously unwell in hospital. Maybe it was the lack of sleep on an overnight flight with a constantly screaming baby but I found myself really profoundly moved by this section. I don’t want to spoil it, but you know what I mean when you get there. I’ve read a lot about illness and sickness this year and this section is a really nice way into what it is like to be seriously, seriously, ill. How that might feel, and how you might perceive things around you. I’m lucky that I’ve never had that kind of experience but I have been around it a lot and I found the warped perspective emotional in the extreme.</p>

			<p>The underlying main theme though is around how we communicate with people. It becomes obvious early on that Sam and Sadie are not good at speaking to each other. They communicate through a medium - namely the games they play and create. This happens early on when their friendship is established over a shared love of Super Mario Bros. Sam playing a game Sadie made for the first time is the catalyst for the rest of the novel. I won’t spoil the game, but Google the board game Train once you’re finished. Throughout the novel, Sam and Sadie have conflicts that are real but that ultimately come from a failure to properly communicate. These are resolved through being creative together or working together, or playing together. It’s just… nice. It’s nice to see a relationship portrayed that isn’t just people talking. There’s very little exposition or (section above notwithstanding) long internal trains of thought. Zevin shows rather than tells and that’s partly why it works so well. We discover along with the characters what was wrong, or what needs to be solved, or how they need to change.</p>

			<p>I’m already looking forward to reading another of Zevin’s books once my partner is finished with it, but I can see why Tomorrow and Tomorrow and Tomorrow was on so many “Best of” lists and why it was recommended to me so many times by so many people. It’s really wonderful and, although some bits lag behind others, the worst bits of this book would be the best of many others. It sets such a high bar for itself that in the slightly slower or clunkier sections you really notice, but it’s only a few pages before you’re back into something really exceptional. Don’t wait until you have a flight to read this on, just do it now. It’s excellent.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "TomorrowAndTomorrowAndTommorowArticle"
	}

</script>


<style>

</style>