<template>
    <div>

		<div class="title">
			<p class="main">Team of Rivals by Doris Kearns Goodwin</p>
			<div class="subheadings">
				<p class="published-date">20th September 2024</p>
			</div>
		</div>
		<div class="article">
			<p>It was 2011 when I first became aware of the authoritative text on Abraham Lincoln’s time as President, Team of Rivals by Doris Kearns Goodwin. A friend of mine was reading it over a summer we worked together at an American summer camp and he recommended it so profusely, I waited just 13 years to read it. He was reading it when the Lincoln biography (creatively titled “Lincoln”) had just been announced and I saw that film more or less as soon as it came out. I have a vivid memory of the guy in front of me at the cinema narrating the plot to his date or partner or whoever was with him with a stream of totally incorrect background facts. I was there with a friend who had studied the US Civil War quite closely and he was getting more and more annoyed. This came to a head when The Guy In Front claimed that the 13th amendment, banning slavery, had not been passed by conference. I had to physically restrain my pal as he almost totally lost his mind in reaction.</p>

			<p>I came to Team of Rivals having already read Grant by Ron Chernow last year so I had some already formed opinions on some of the characters who feature prominently here. Two main takeaways: I was right to think that General George McLellan was an absolute unmitigated arsehole, and I was wrong to think that William Seward was bitter about missing out on the Republican nomination to Lincoln in 1860. Those aren’t the only interesting bits here but they are indicative of the main thrust of the book. Lincoln was a master of “man management”. The title refers to the major cabinet offers and their rival aims, ambitions, and desired outcomes from the Civil War. Again, I don’t want to rehash the contents of the book, but it’s worth reflecting on how it’s structured and what it means for the exploration of the various key relationships.</p>

			<p>The initial few chapters cover the early, personal, and initial political lives of the likes of Lincoln, Seward, Edward Bates and Salmon P. Chase. There’s no getting away from the fact, by the way, that “Salmon P. Chase” is a funny name. Chase himself knew this and Goodwin references a passage from his diary where he wishes he had been named differently. These initial chapters culminate in the nominating conference for the 1860 Republican candidate for president. During the presidency of James Buchanan (record holder for best nickname with “The Ol’ Public Functionary”) and with the country on the verge of civil war, Seward was widely seen as the favourite for the nomination. He had been a leading light of the abolitionist movement. His speech referencing that while slavery may be constitutional, it fell foul of “a higher law” of morality made him the Bête Noire of the south, and of northern Democrats who feared civil war. Goodwin covers that Lincoln, despite a relatively nondescript political career so far, had main himself the acceptable choice to all wings of the party, while being no one’s favourite. Chase and Bates were also contenders for that nomination but both were probably over confident in their chances according to Goodwin. The contest could be broadly described as Seward versus Anyone But Seward, with the anti-Seward vote coalescing behind Lincoln.</p>

			<p>Despite feeling like his true entitlement had been taken from him, Seward became one of Lincoln’s staunchest allies and closest friends. The two had an extremely close relationship, being similar in temperament and attitude to the war. Lincoln took Seward’s criticism seriously and, as Secretary of State, he was probably the second most powerful person in the country. More so than the Speakers of each House of Congress (who are mentioned very little) and Vice-President Hamlin. Seward was also a lightning rod for criticism. Because of his history, the more conservative Republicans and Democrats didn’t trust him to prosecute the war in pursuit of reunion over ending slavery. Similarly, as the practicalities of government softened some of the harder edges of his opinions, radical Republicans, such as Chase, turned on him.</p>

			<p>Chase took up his position in the cabinet somewhat against his better judgement. He was expecting to be offered the position of Secretary of State, the idea being that this would set him up to run in 1864 in an era where single term Presidencies were the norm. A staunch abolitionist, Chase was converted to Lincoln’s cause essentially by the Emancipation Proclamation, which he saw as the key to the war. The recruitment of black soldiers, also advocated by Frederick Douglass, was another policy that Chase was glad to see Lincoln take up. Despite this, Chase never really felt comfortable it seems and his ambition for the presidency never dwindled. He clashed constantly with members of the cabinet he perceived as insufficiently radical on the slavery question and submitted his resignation 4 times. When his bluff was finally called by Lincoln, he was devastated to lose his position. Lincoln tolerated the insubordination by Chase for far longer than others would have or could have. His political manoeuvring managed to keep Chase in the cabinet and happy (with caveats) for the majority of his time in office.</p>

			<p>Other cabinet members were similarly out-manoeuvred by Lincoln. He managed to keep Bates and Chase happy working together despite their extremely different views on slavery. He had Edwin Stanton as war secretary until the bitter end despite Stanton’s former disdain for and criticism of the president. Stanton is one of the stand-out performers of government in Goodwin’s view. Results focussed, his relationships with others were less close except of that with Lincoln, whom he grew to admire immensely. These relationships and the “dressing room management” of Lincoln are really the heart of Team of Rivals.</p>

			<p>There is barely a person mentioned in Team of Rivals who doesn’t pay tribute to Lincoln’s mastery of personal politics. His ability to keep people in line - even in seeing from the start that his cabinet had to contain people who disagreed with him and each other - and to keep the atmosphere positive was essential in his success. Multiple references are made to Lincoln’s sense of humour (with all the incongruity of trying to communicate a joke across 150 years) and his repeated telling of “funny anecdotes” or reading out from the latest, and I’m not making this up, joke book. His magnanimity and ability to give away praise and take on criticism are all elements of his character that led to people liking and trusting him even when they may have initially been disinclined to.</p>

			<p>The political intrigue is interspersed with both military and personal matters. Tragically, Lincoln’s son William died during his presidency. This death took a huge toll on his wife Mary who was much maligned by the writers of the day and many of the political figures. Her competition with Chase’s daughter, Kate, to be the star socialite is one of the less interesting B-plots in my opinion. The families of the various cabinet members are importantly referenced though. Mary’s depression and sometimes erratic behaviour caused a few minor scandals. Edward Bates’ devotion to his family made his time in Washington difficult as he yearned for the quiet life. William Seward’s wife Frances’ opposition to slavery stayed on the more radical side of the Republican spectrum even as her husband had to temper his views slightly which caused some friction between them. Militarily, Lincoln seems to have been repeatedly let down by poor leadership. Edwin Stanton’s excellence notwithstanding, the actions of generals like George McLellan, in Goodwin’s view, lengthened the war significantly and had enormously negative effects on the North. McLellan comes across as a grade A dick throughout, an opinion I more or less already held after reading Grant last year. Extraordinarily cautious, McLellan seemed the polar opposite of Lincoln. Nothing was ever his fault and every success was down to his genius, in his view. After the nightmare of the Peninsula Campaign, McLellan is finally removed only to crop up again as the Democratic candidate in 1864. With the arrival of Grant though Lincoln and Stanton had a leader they could trust and the progress of the war changed accordingly.</p>

			<p>Ultimately this is a book about relationships and how to manage them effectively. Yes, Lincoln was probably a bit of a lucky general in that he had the right people around him but only because he marshalled them effectively and kept the team focussed on what was to be achieved. Historically, he often ranks above Washington in lists of greatest presidents and it’s not hard to see why when you see how much of his presidency and the civil war was defined by personal relationships that he held. Obviously the big policy calls and military strategy were hugely important too, but had it not been for Lincoln and his sometimes contentious cabinet, we would be living in a very different world.</p>

		</div>

    </div>
</template>


<script>

	export default {
		name: "TeamOfRivalsArticle"
	}

</script>


<style>

</style>