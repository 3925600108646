<template>
    <div>

		<div class="title">
			<p class="main">France - A History: From Gaul to De Gaulle by John Julius Norwich and John Murray</p>
			<div class="subheadings">
				<p class="published-date">11th July 2024</p>
			</div>
		</div>

		<div class="article">
			<p>A combination of elections and European championships very much got in the way of my reading over the last month or so, meaning it took me way longer to get through France -  A History: from Gaul to de Gaulle by John Julius Norwich and John Murray. If, like me, you get your Louis’s mixed up, and you have no earthly idea why France went from Napoleon to Napoleon III, this is a good (but relatively light) history of the rulers of France and their impact.</p>

			<p>Starting with the tribes of Gaul (as well as providing a fun pun for the title (groan) has a very “Starting a history of England with 1066” feel. Obviously France existed geographically before then and there were dinosaurs before that and Pangea before that but starting around literally the year 0 is as good a time as any. From my time playing Rome: Total War, which I felt was also strong revision for my Roman Law exam, I had a reasonably good knowledge of the structure of Gaul of a kingdom to the extent it can be called one. We obviously lack a lot of primary sources for that period compared to later ones covered but I’m still glad that we started there rather than with Charlemagne or another figure who might be considered the founder of the French nation. It gives a good level of context and is helpful in understanding how the Frankish kingdoms came to be united in the medieval period.</p>

			<p>Another Total War game helped ground this section for me too though I had basically no knowledge of the Carolingian kings or empire. The rise of Christianity (spoiler alert: this gets more complex) is the main thing and, while interesting, I was kind of coming to this book for a political history rather than a religious one. Obviously throughout history religion has influenced politics especially in monarchical societies, but it just didn’t grab me at the same level. My reaching this section also coincided with the crunch period of the election so I was probably finding my attention was dragged away more than at other times.</p>

			<p>We really get into the meat of where I was properly interested (and confused) when we get to the reformation and the French Wars of Religion. I appreciate I’m contradicting myself slightly here by saying a period hugely influenced by religion was more interesting, but here we really get into the influence of religion on politics and the monarchy. We also reach the proper height of the absolute monarch in Louis XIV. The move to Versailles, the chaos of that court, it was brilliant to read so much about the context in which the revolution came about. Furthermore, I hadn’t been properly aware of what had happened in the Netherlands and Spain at this period. I think of this period of French history as being relatively inward looking but in fact it was largely this interaction with the other states and kingdoms of Europe that created the debt and conditions for the revolution.</p>

			<p>France has had a number of revolutions and it’s a period of their history that I am relatively familiar with. This is in no small part because so much of modern jurisprudence was set in this time. The rise of Robbespierre and the work of people like Tallyrand are covered in detail, and of course the Reign of Terror, the metric calendar and various other interesting stories give some good colour. Of course the Napoleonic Wars and his empire are fascinating and the various endless political tumult of restoration, Napoleon III, further instability and the establishment of the third republic. This section reads like a thriller more than a history and is, perhaps expected, the most interesting section.</p>

			<p>The book finishes with a discussion of the unification of Germany and the two world wars. It ends relatively abruptly when I was looking forward to coverage of the Algerian crisis and De Gaulle’s career. This was probably the most disappointing aspect of the book. Obviously a history has to end somewhere or you’d eternally be having new pages delivered. The end of the second world war and the (relative) stability that followed is as good a place as any but I was disappointed nonetheless.</p>

			<p>I’m definitely glad I read this history of France, but I do think I’ll have to dig a bit deeper in Wikipedia to follow up on questions than I was hoping to. I’d also say a lot of stuff is covered really only very briefly. Entire reigns can get only a paragraph. Decades covered in a page. This is a trade off anyone has to make and that’s fine, but I will want to follow up with something else on the areas I’m still not super clear on.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "FranceArticle"
	}

</script>


<style>

</style>