<template>
	<div class="container">
		<div class="row">
			<div class="col-sm">
				<p>Git</p>
				<p>JIRA</p>
				<p>Semaphore (CI/CD)</p>
				<p>SQL</p>
				<p>Azure</p>
			</div>
			<div class="col-sm">
				<p>PostgreSQL</p>
				<p>Heroku</p>
				<p>PHP (Laravel)</p>
				<p>Javascript (Vue.js)</p>
				<p>Clickup</p>
			</div>
			<div class="col-sm">
				<p>CSS</p>
				<p>NPM</p>
				<p>Sketch</p>
				<p>AWS</p>
				<p>Confluence</p>
			</div>
			<div class="col-sm">
				<p>Figma</p>
				<p>Pivotal Tracker</p>
				<p>Trevor.io</p>
				<p>Clubhouse</p>
				<p>Databricks</p>
			</div>
		</div>
    </div>
</template>


<script>

	export default {
		name: "TechnicalSkills"
	}

</script>


<style>

</style>