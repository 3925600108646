<template>
    <div>

		<div class="title">
			<p class="main">Inverting The Pyramid: A history of football tactics by Jonathan Wilson</p>
			<div class="subheadings">
				<p class="published-date">9th August 2024</p>
			</div>
		</div>

		<div class="article">
			<p>There was me thinking I could get back into a routine of reading, forgetting that I had niche sport to become obsessed with during the Olympics. Nonetheless, I got through Inverting The Pyramid by Jonathan Wilson in good time in no small part because it’s so readable. Despite being a quite dense history of football tactics requiring a need to repeatedly imaging complex formations and transitions in your head (the GIF has surpassed the Page in at least one thing), it really kind of flies by if you have any interest in football at all. I came for Celtic beating Inter Milan in 1967, but I stayed for Bela Guttman.</p>

			<p>I don’t like to rehash the entire contents of books when writing about them but an overview is pretty much required if you’re to draw any conclusions about what Wilson has to say. So here we go. I’m going to skip the basic evolution of the game, its rules, and position of manager that Wilson does cover and take it as a given that we have football teams playing football in a basic manner we might recognise.</p>

			<p>The first truly influential figure Wilson covers is Jimmy Hogan. A proponent of “the Scottish Game” (read ‘short passes among several forwards’), Hogan worked throughout Austria-Hungary in the early 20th century. Hogan and other coaches of the Danubian School brought this highly technical proto-Total Football to Europe at a time when the UK, and England in particular, was in an extremely pragmatic kick and rush phase. This changed, slightly, with Herbert Chapman’s WM formation most famously at Arsenal. I found this especially interesting because as an obsessive player of Football Manager, but someone who has never really thought seriously about tactics, I remember ‘discovering’ the WM myself in about 2005. I won everything in sight with a forward two of Cavenaghi and Tevez. I’m skipping over a lot of really interesting stuff here, especially with the Hungarian national team and a very early iteration of the False 9 role later made famous by Lionel Messi, but you’ll need to read the book for that.</p>

			<p>After these initial evolutions from chaos to organised chaos, we enter the era that sets the structural tone for the rest of the book. Yes, Hogan v Chapman could be characterised as the romantic versus the pragmatic but this comes to much more of a head as we encounter Charles Reep, Vittorio Pozzo, and Helenio Herrera on one side, and Rinus Michels and Bela Guttman on the other. This is really the heart of the book. The stoic pragmatism and discipline of the former versus the emotional expression of the latter. Reep in particular is painted as an absolute villain for perpetuating myths around long ball build up. Wilson’s disdain for Reep, while justified, is dripping through this book when in my opinion he was a guy who got some stats wrong. The likes of Herrera, who were unapologetically anti-football deserve this status as Head Bad Guy much more. Pozzo once described the perfect game as finishing 0-0. Astonishing.</p>

			<p>If you’re me, and you are not tactically minded, the philosophy of Guttman and Michels is much more appealing. These focussed more on controlling space through pressing, and compressing the game into the area you want to attack, as opposed to the more defensive giving up of space and the ball to remain compact and hard to break down. This more aesthetic (Wilson’s word, not mine, though I agree in every sense) model of the game evolved through managers like Lobanovskyi, who brought a more analytical model, and Saachi, who brought zonal marking and collective play, essentially finding the 70s purest form in Cruyff’s Total Football.</p>

			<p>The final few chapters of the book (I’m ignoring the chapters on why England are bad because I don’t share the author’s particular obsession with that question) cover more modern football, focussing heavily on Bielsa, Guardiola, Mourinho and Conte. These four are painted as carrying on the same battle that wracked the 70s and 80s with the former presenting a more beautiful game than the latter. It’s at this point that the conclusions start to become a bit confused.</p>

			<p>Guardiola’s Barcelona team were magical. There is no dispute about that. But his Manchester City team is sanitised and dull. The tactical evolution from Total Football to what is now called Positionally Play may be overblown from what Wilson has to say. In fact, the more revolutionary aspect is the move from players being specialists to universalists. I’ve always maintained that at the top level, players are about as good as each other. Messi can do as many keepy-ups as, say, Kyogo Furuhashi. But Messi is probably the greatest player of all time. So what is the difference? Is it tactical system? Raw talent? Is it that the game isn’t played with good feet but with good everything?</p>

			<p>To me Wilson gives too much credence to small changes in the tactical mainstream. I’m not saying this is the end of history, and I’m not saying tactics don’t matter. I used to think that, and Wilson has convinced me that they do. But I also think it’s not a coincidence that in the more recent international tournaments, it's been the teams with the best stand out players who have tended to succeed. Even Spain this summer relied heavily on Nico Williams and Lamine Yamal. Wilson makes the extremely valid point that the international game is almost totally different from the club game now in no small part because tactics can’t be impacted in the short periods given over to international football. But that doesn’t explain the move to teams having extraordinary individuals. If Guardiola’s City are so good, why did they sign Haaland? How did Ancelotti win the Champions League with a tactic that was basically “Enjoy yourselves boys”? The conclusion I find myself drawing is that football tactics have reached something of a nadir in the current iteration of Total Football that most top teams play. But that means that more great individuals will be required. And that applies as much to managers as players. I give you Sir Alex Ferguson for example.</p>

			<p>Inverting The Pyramid is a great book that does exactly what it sets out to do. But I want to remain an aesthete, and I want great skill and having a go to be part of the game. So I’m going to keep believing that even if Wilson has probably convinced me that I’m fighting a losing battle for romanticism. “We want to make neutrals glad we won it” is something to aspire to. I hope football still does.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "InvertingThePyramidArticle"
	}

</script>


<style>

</style>