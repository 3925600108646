<template>
    <div>

		<div class="title">
			<p class="main">The End of the Bronze Age by Robert Drews</p>
			<div class="subheadings">
				<p class="published-date">20th February 2024</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>I have been obsessed with the Bronze Age Collapse for years. It is fascinating to me that so many relatively advanced civilizations collapsed in the space of a couple of decades. And one of the most advanced ancient societies, the Egyptians, never again reached the heights they had. That our best theory for why this happened essentially boils down to the meme “Everything changed when the fire nation attacked” is absolutely incredible to me. The End Of The Bronze Age by Robert Drews, my second book of the year, attempts to explain the origin of the Sea Peoples (yes, the Sea Peoples) and their impact.</p>

			<p>Drews begins by discussing the geography of the area at the time. Not being versed in near east antiquity, a lot of this was lost on me to be honest. But it is important to one of the most interesting discussions in the book - a take down of the previous orthodoxy that a mass migration from northern Europe caused the invasions. My previous reading on this topic had led me to the conclusion that some environmental catastrophe like a drought had caused a mass migration from the Balkans, Italy, and Sardinia, and that these destitute people had been seeking new lands. Drews dismisses this. There is no record of a drought for a start. We have good models of environmental conditions as well as archaeological techniques to determine them. None of them suggest a serious drought or crop failure of any kind. Not to mention the journeys allegedly taken make no sense. I won’t discuss these at length here as I would just bastardise the book, but there is one important thing to note: the fact that the cities around the red sea were not conquered, but razed to the ground almost invariably, tells us these were not environmental refugees. They were invaders seeking to destroy rather than conquer.</p>

			<p>Drews spends a lot of time on the military techniques of the bronze age. Again, a lot of this was new to me and being a relative academic text it assumed a lot of knowledge I didn’t have. In summary though, chariots were one of the main military technologies of the time. Archers would stand on the back of chariots and fire from the platform that the cart gave. This was hugely effective against the primitive infantry of the time but Drews discusses archaeological finds that suggest the Sea Peoples had found a counter - spears and phalanxes. We think of the phalanx as a Roman technique but in fact there is clear evidence of large spears and small javelins being used by huge fast moving battalions of infantry to overwhelm the small numbers of chariots. We also see this in Egyptian frescos as the invaders are depicted on foot coming from boats with small hand held spears.</p>

			<p>The second advance that Drews claims is in swords. The Naue Type II sword, originating in northern Europe, has also been evidenced in Crete, Palestine, and Egypt around the time of the bronze age collapse. This new technology proved hugely effective against the military technique of the age. Swarming is the name Drews gives to the technique of overwhelming numbers of men armed with short swords designed to slash at horses and archers. In close combat, these bested archers every time. The Egyptian repulsion of the invasion appears to have come from attacking the arriving boats early before fighters could gain any kind of momentum to rout chariots. It was this defensive technique that Drews says led to the establishment of coastal cities in the near east in the iron age.</p>

			<p>One thing I really wanted to know more about, but that isn’t discussed by Drews, is the resulting system collapse. Wars were fought constantly in the bronze age with cities attacked without society totally breaking down. My next bit of reading on this topic will hopefully be more focussed on why it was that these invasions caused such a permanent degradation of bronze age society. One theory is that the collapse of one, say in Crete, meant that trading was disrupted and neighbouring societies were less able to thrive once one was gone. I still don’t feel entirely well informed on the bronze age collapse, but the military angle was one I hadn’t really considered.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "BronzeAgeArticle"
	}

</script>


<style>

</style>