<template>
    <div>
		<div class="row">
			<div class="col-sm">
				<p>email: <a href="mailto:me@joshgraham.io"><br>me@joshgraham.io</a></p>
			</div>
			<div class="col-sm">
				<p>github: <a href="https://github.com/joshjamesgraham"><br>/joshjamesgraham</a></p>
			</div>
			<div class="col-sm">
				<p>address: <a href="https://goo.gl/maps/nTAHzxHRwixRKNQTA"><br>Margate, Kent</a></p>
			</div>
		</div>
    </div>
</template>


<script>

	export default {
		name: "ContactDetails"
	}

</script>


<style>

</style>