<template>
  <div id="app">
    <NameAndTitle/>
    <ProfilePicture/>
    <MainMenu/>
  </div>
</template>

<script>
import ProfilePicture from '@/components/partials/ProfilePicture.vue'
import NameAndTitle from '@/components/partials/NameAndTitle.vue'
import MainMenu from '@/components/partials/MainMenu.vue'

export default {
  name: 'App',
  components: {
    NameAndTitle,
    ProfilePicture,
    MainMenu
  }
}
</script>

<style>
#app {
  font-family: "Lustria", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #434342;
  min-height: 100vh;
  padding-bottom: 50px;
}

li {
  font-size: 16px;
}

p {
  font-size: 16px;
}

a {
  color: #FCBA03 !important;
}

a:hover {
  color: #ffcf4a !important;
  text-decoration: none !important;
}

</style>
