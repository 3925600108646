<template>
	<div class="menu">
		<h1 v-on:click="toggleExperience">Experience</h1>
		<transition name="slide">
			<ExperienceDetails v-if="showExperience"/>
		</transition>
		<hr>
		<h1 v-on:click="toggleHighlights">Highlights</h1>
		<transition name="slide">
			<WorkHighlights v-if="showHighlights"/>
		</transition>
		<hr>
		<h1 v-on:click="toggleSkills">Skills</h1>
		<transition name="slide">
			<TechnicalSkills v-if="showSkills"/>
		</transition>
		<hr>
		<h1 v-on:click="toggleContact">Contact</h1>
		<transition name="slide">
			<ContactDetails v-if="showContact"/>
		</transition>
	</div>
</template>


<script>
	import ExperienceDetails from './ExperienceDetails'
	import WorkHighlights from './WorkHighlights'
	import TechnicalSkills from './TechnicalSkills'
	import ContactDetails from './ContactDetails'

	export default {
		name: 'MainMenu',
		components: {
			ExperienceDetails,
			WorkHighlights,
			TechnicalSkills,
			ContactDetails
		},
		data() {
			return {
				showExperience: false,
				showHighlights: false,
				showSkills: false,
				showContact: false,
			}
		},
		methods: {
			toggleExperience() {
				if (this.showExperience === false) {
					this.showExperience = true
				} else {
					this.showExperience = false
				}
			},
			toggleHighlights() {
				if (this.showHighlights === false) {
					this.showHighlights = true
				} else {
					this.showHighlights = false
				}
			},
			toggleSkills() {
				if (this.showSkills === false) {
					this.showSkills = true
				} else {
					this.showSkills = false
				}
			},
			toggleContact() {
				if (this.showContact === false) {
					this.showContact = true
				} else {
					this.showContact = false
				}
			},
		}
	}
</script>

<style>
.menu {
	margin: auto;
    max-width: 85%;
    margin-top: 60px;
    color: white;
}


@media only screen and (max-width: 767px) {
	menu {
		max-width: 90%;
		min-width: 90%;
	}
}

hr {
	border-color: white;
	height: 5px;
}

.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter, .slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>