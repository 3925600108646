<template>
    <div>

		<div class="title">
			<p class="main">Expected Goals by Rory Smith</p>
			<div class="subheadings">
				<p class="published-date">5th March 2024</p>
				
			</div>
		</div>

		<div class="article">
			<p>Well, I had to hit a bum note eventually and by God did I with Expected Goals by Rory Smith. Highlights: We get it Rory, everyone has heard of Moneyball; I have never, in all my life, read so many, such an abundance, all incomprehensible and meaningless, sentences with subordinate clauses. I found myself getting more and more annoyed and skimming more and more of this book waiting for something interesting to happen. It never did. The conclusion is obvious and could be surmised by even the most junior student of football: Football clubs know what data is but they don’t use it very well.</p>

			<p>Now look, I can’t exactly throw stones from my position in the Glass House Of Poor Prose. But in my defence, I’m not a paid writer. Part of why I’m writing these long form reviews is because I haven’t written long form for about 15 years and figure I could use the practice. But honest to God. It’s unbearable.</p>

			<p>The book is tangent after tangent, all told in that annoying “you’ve just dropped in on this scene” kind of way. If I read another chapter beginning “John walked through the glass doors with his heart aflutter. This wasn’t his world - he was a numbers guy, not a sports guy” ever again in my life I expect a fully fledged panic to descend upon me. Oh no, I’m back in the Rory Smith Extended Universe where assorted data analysts live the same day over and over. It’s Groundhog Day. It’s Palm Springs. It’s not a coherent narrative! Rest assured, John will go on to found his own data company. He’ll struggle for clients before eventually getting one. He’ll recommend they sign a player that they refuse to (and that young Argentinian? Lionel Messi!) and then he’ll leave. The next chapter will begin. Steven moved to exit his car and didn’t feel at all confident that his feet would propel him into The Football Building. Oh God I’m having another cluster migraine.</p>

			<p>Rory Smith sets out to write about football’s data revolution. He tells the stories of the founding of Opta, and WyScout, and ProZone, and Decision Technology. He talks at length about an academic who wanted to buy Charlton FC to prove data could run a club better (spoiler: he doesn’t, and he can’t). He talks about Arsenal and Spurs bringing data capability in house, and how clubs are now frequent attendees at sports data conferences. If this is the data revolution, we should be devastated that it has been televised. All the clubs Smith lists as examples of best practice were using these companies during some of their lowest ebbs. Arsenal in the twilight years of the Wenger era and Spurs immediately pre-Pochettino. How is that a successful revolution? Smith ends up making the case against data in an ironic revolutionary twist that would shame Robbespierre. </p>

			<p>To the extent that the book has anything interesting to say it is this: Hey, the guy who bought Liverpool in 2010 sure is good at running football clubs! Yeah, eventually. John Henry arrived at Liverpool after a Moneyball inspired success at the Boston Red Sox but it took years for any significant turnaround to happen; and from Smith’s account very little of it was inspired by data. It all kicks off when everyone realises Jurgen Klopp is a good manager. What an inspired insight! The book doesn’t make the case that it sets out to make. It in fact took me from having the opinion that a well analysed club and team would have more success to thinking the opposite might be true. That actually having a single visionary person might be the only way to success and that it simply isn’t possible to create a system for sustainable data driven good results in football. I know that isn’t true and yet I came away from this book considering it.</p>

			<p>I’m not even putting an affiliate link on this review.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "ExpectedGoalsArticle"
	}

</script>


<style>

</style>