<template>
    <div>

		<div class="title">
			<p class="main">Washington: A Life by Ron Chernow</p>
			<div class="subheadings">
				<p class="published-date">18th February 2024</p>
			</div>
		</div>
		<div class="article">
			<p>Normally by mid-February, I’m on my 3rd or 4th book of the year. Not this time though with the 817 pages of Washington: A Life by Ron Chernow taking a bit longer than expected. Though having read his biographies of Grant and Hamilton last year I’m not sure what I expected. The book is so long because it’s so thoroughly researched with Chernow having gone through page after page of Washington’s diaries and letters and reports.</p>

			<p>We all know who George Washington was, but it’s worth recapping that he started work as a surveyor under the British, and indeed fought under a British flag at the start of the Seven Years War. He became disillusioned with British rule while seeking a commission in the British army. After being continuously let down, he became enamoured with the idea of American independence and led the Continental Army though the Revolutionary War. His venerated status after the war made his election as first President something of a formality. Having intended initially to serve only a partial term, he led the States for 8 years before retiring to his plantation in Mount Vernon. The book is split into several parts covering Washington’s time as junior soldier, military leader, and president, with his interregnum years covered in some detail.</p>

			<p>I’m not normally one for military history. I found a lot of the civil war battle details in Grant quite grating. Grant’s position as a military genius is unquestioned but Chernow, despite being highly supportive of Washington’s place in the pantheon of truly great American presidents, casts some aspersions on Washington’s own military prowess. What I found interesting though was Chernow’s position that Washington was a great leader. He may not have been a great tactician. He may not have been a great policy guru (a position taken in his cabinet by Hamilton). But he was undoubtedly a great leader of people.</p>

			<p>Chernow backs this up convincingly in his coverage of the winter encampment at Valley Forge. With no supplies, and deserting soldiers, Washington remained with his men despite an overwhelming urge to return to his wife and family in Mount Vernon. The survival of the Continental Army through that worst of winters was crucial to the American revolution. Washington’s own resolve to make it through inspired his men to do the same. His tactical ability could easily be questioned though. The massacre of his men at Fort Necessity, his obsession with recapturing New York despite the, correct, French plan to instead attack Yorktown, his general submission to French military leadership. All of these suggest Washington was not in the same league as the great military leaders.</p>

			<p>One of the things I really would not have guessed before reading this biography is that Washington was a bit of a frustrated womaniser. Chernow makes repeated references to Washington being the life and soul of the party - but only if women were around, and only after a few drinks. As he got older this flirtatious mood seemed to leave him though, and his relationship with his wife Martha matured. Martha is repeatedly and somewhat unkindly described as being not a typical beauty. That she and George were best friends rather than enamoured lovers is a well understood fact. But this relationship was the making of him in truth. He doesn’t appear to have had affairs, and his constant desire to return to a small time farmers life in Mount Vernon shows Washington was, at heart, a family man</p>

			<p>Few this show this more than the extraordinary kindness to family members that Washington showed throughout his life. While he had no biological children, several deaths in his family and Martha’s left him with a number of dependents. Not least the Custis children and grandchildren from Martha’s first marriage. Jacky and Washy Custis in particular took a lot of Washington’s time, energy, and money to little avail. Their house though was always a hub of activity and children with several acting as managers or overseers at the Mount Vernon plantation. We think of Washington as the Father of his Country but in truth his focus was much more narrow.</p>

			<p>According to Chernow, Washington felt the President should be above the political fray, and this can certainly be seen in his farewell address, but it is beyond question that Washington was a Federalist who believed in strong central government. He didn’t only support Hamilton’s plans for the federal government, but he also remained close with Hamilton later in life in a way he didn’t with Jefferson and Madison. That these two had been the main drivers behind anti-Federalist feeling is clearly part of that.</p>

			<p>Something that really struck me was the image Chernow conjures of Washington as someone slightly insecure in his position. He fought for a commission in the British army and not getting it helped turn him towards independence; he was fanatical about his step-children’s education having not received one he was proud of; he took his mothers insults so closely to heart despite her clear disdain for him and his position. Yet at the same time he hated public adulation. For a man who was basically a god to his people at the time, and who was offered a crown as King of America, he went out of his way (literally on some journeys) to get around the parades towns would throw for him. He wanted the approval of his kind of person - not the ordinary people. It was probably this slight elitism that led to his biggest foreign policy decision.</p>

			<p>Chernow sets out to refute the idea that Washington was a figurehead who was led by his more esteemed cabinet colleagues. Nowhere is this more obvious than in his refusal to take a side in the French revolution. Despite strong petitions from Jefferson that the Americans support the Revolution (and indirectly help Washington’s close friend the Marquis de Lafayette), Washington was resolute in his opposition to active foreign policy. This led to him being portrayed as being overly close to Britain, as an elitist, and led indirectly to a stand off with French ambassador “Citizen” Genet. But Washington could not be moved on it. It’s interesting that Washington did not seek to be a global revolutionary. Ultimately, he was seeking what was best for America and for his people. He had strong beliefs, but he wasn’t an ideologue.</p>

			<p>Many of Washington’s male relatives died young. For a while he felt he may be cursed but in fact led something of a charmed life, surviving malaria, and dysentery twice. It’s something of an obvious point but perhaps his greatest act was resigning the presidency. In an era of tyrants and monarchs, the idea of a peaceful transfer of power was a new one. Admittedly he may have felt differently had Jefferson won the presidency rather than his fellow Federalist Adams, but this was a chance he took when resigning. His plan was to return to the family plantation in Mount Vernon with Martha to see out his days and return the business to profitability after years of absence. It was not to be though as within just two years, after taking a horse ride in a blizzard, he developed quincy. Nowadays, quincy is a dangerous disease but easily cured with antibiotics. Washington obviously did not have access to this kind of treatment and was instead repeatedly bled to attempt to purge the infection. This in fact left him weakened, gasping for air, and in agony in his final hours. He died aged just 67. A good age, but hardly enough.</p>

			<p>It is impossible to think about Washington, or indeed any American founding father, without discussing slavery. Despite his great desire for liberty, Washington owned several people - a deranged statement to make in reality. He protests in his letters and diary that he wanted to free his slaves, and that he was a kind master. One wonders to what extent this can be true at all. To own a person is such an evil act that it is impossible to ever frame it as a kindness. The record also shows that he continued to employ violent overseers and encouraged the punishment of idleness among his slaves. Washington was a racist who believed black Americans were better off owned by whites. He may claim to have wanted to free his slaves, and feel that slavery was not an efficient business model, but he ultimately did not free them. He may have treated some with real respect and kindness, but all of this is in the context of a relationship of ownership. He also tried extremely hard to find escaped slaves, and found loopholes in Pennsylvania law to keep slaves in servitude. At the time, a slave who spent several months in the state would be automatically freed. To avoid this, Washington returned slaves to Virginia periodically to keep them enslaved. This is hardly the act of the benevolent master who in his heart of hearts wanted to free them.</p>
			<p>Having read Chernow’s biographies of Hamilton and Grant, I was excited to read this book. It didn’t disappoint. However, as with his other books, I found Chernow’s straining to excuse bad acts or character flaws frustrating. I visited Monticello in 2019 and found that the discussion of Jefferson as a brilliant polymath who was also ultimately a racist to be far more credible than Chernow’s depiction of Washington as a put-open slave owner. Ultimately, good and brilliant people can do bad things, and all live in the context of their time. But there were manumission societies all over America at the time. Slavery in the UK was abolished just 8 years after his death and his aide Hamilton was strongly against slavery (despite trading slaves for family members) so it’s not as if Washington could claim ignorance. My main takeaway from this story is probably one about leadership. Washington did not have the military genius of his contemporaries, or the intellectual heft of his closest aides, but he was a leader. There’s an old joke that if Usain Bolt’s running coach was a better runner than Bolt himself, he would be the fastest runner in the world. Leadership and management are different skills to the on the ground things that are run. This was where Washington excelled. And in fact, thinking about him in this way rather than as some rarefied  God-like figure makes his achievements all the more amazing.</p>
		</div>

    </div>
</template>


<script>

	export default {
		name: "WashingtonArticle"
	}

</script>


<style>

</style>