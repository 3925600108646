<template>
    <div>

		<div class="title">
			<p class="main">Four Ways Of Thinking by David Sumpter</p>
			<div class="subheadings">
				<p class="published-date">18th February 2024</p>
			</div>
		</div>
		<div class="article">
			<p>I’ve been aware of David Sumpter for a while, since my brother did his Erasmus year in Uppsala where Sumpter is professor of applied mathematics. And if you’re a fan of pop maths books (as I am) then you will eventually come across Sumpter. The last book of his that I read, Outnumbered, was an interesting look at “the power of algorithms”. You may remember that phase about 5 or 6 years ago where everyone was convinced Facebook and Instagram were secretly using their phone microphones to spy on them and serve ads? The ads were uncanny in their accuracy, so it went, that there had to be some kind of trick. A former boss of mine who didn’t buy this theory spent 2 weeks intermittently shouting “BREAD” at his phone to see if he was advertised a loaf. Warburton’s clearly didn’t get the message. The ad never appeared. Sumpter made a convincing argument in Outnumbered that actually one you have two or three data points about someone, you can make some pretty good assumptions about them. Facebook doesn’t need to spy on you. You’ve told it everything it needs to know.</p>

			<p>This idea of following simple rules (a person who tells Meta they are female and aged 25-40) to end up with something rather more complex (a complex ad journey intended to accompany that person through a pregnancy) is also at the heart of Four Ways of Thinking. The book is in 4 parts, each relating to a ‘way of thinking’, and oscillates between explaining the history and theory and various mathematical systems that correlate to these ways of thinking, and a somewhat fictionalised account of Sumpter’s time in Santa Fe as a PhD student. As a student, Sumpter eventually finds himself working on cellular automata - a perfect example of complexity or chaos arising from simple rules. It is the evolution of Student Sumpter’s understanding of automata and how they can be explained by Stephen Wolfram’s ideas on systems thinking that give the book its structure.</p>

			<p>The point of Four Ways of Thinking is somewhat different to Outnumbered even if, by the nature of the author’s areas of interest, there is some overlap. Instead, Sumpter here wants to take us through evolutions in systems theory. We start with statistical thinking. You can understand the world by counting things. I’ve got to say while this section is interesting, I didn’t really need a professor of applied mathematics to tell me that counting is useful. From inflation calculations to covid cases - we all know what it is to think statistically or just ‘using numbers’. Statistical thinking is extremely common and extremely useful, but it falls down when it tries to explain more complex things. Sumpter refers to this next step as interacive thinking. Based heavily on the work of Alfred Lotka, the example given here is a predator-prey model of population. Statistical thinking would be to calculate a current population and a birth rate. But how do you explain the movements in population? That is an interaction. As populations grow, it becomes less easy to support them. So they drop (or are hunted) until they can be sustained or the hunters can no longer support their now increased population. We can’t just count the number of rabbit and foxes in an area and understand their ecosystem. We need to understand the interactions.</p>

			<p>But what if the interactions aren’t revealing what you expected. This is what Sumpter would call chaotic thinking. The butterfly effect, the story of which he tells brilliantly, or Conway’s Game of Life automata can be thought of as examples of chaotic systems. It’s here too that we meet Claude Shannon (for whom, presumably, the GPT by Anthropic is named) and his wife Betty. They devised a way to determine the entropy in language and found that actually in most cases with enough information, you can predict what letter would come next in any piece of text. Does this sound familiar? Predicting what comes next in a line of text? This is basically how modern AIs work. And it can all be drawn back to Shannon as the father of information theory. To me this was the most interesting section of the book. I have a passing knowledge of cellular automata, and of LLMs, so to get this insight into the foundational theory of them was brilliant.</p>

			<p>We finally come up against complex thinking. Early systems theorists would say that “a problem is as complex as its simplest explanation.” Take for example a list of digits with no pattern. These could be binary 1s and 0s or something like pi. If there is no pattern or way to summarise the concept then it can be regarded as highly complex. Someone who understood this with an almost frightening intuition was Andriy Kolmogorov, a Soviet mathematician who had a reputation among his students for being able to sum up their years of work with a single sentence, totally under cutting the perceived complexity of the work. I found the stories of Kolmogorov interesting, but this idea of complexity did not chime with me. It struck me as a definition for its own sake and while I do like the idea of trying to define complexity, especially in contrast to something chaotic, the description idea drawn from information theory didn’t seem to click for me. But that’s almost certainly because I don’t have the capacity to understand it!</p>

			<p>I enjoyed Four Ways of Thinking, but I think Outnumbered made the more novel points from my perspective. It’s possibly because in my day to day work I think in terms of chaos and complexity that are quite different to these, but I was more interested in the maths of Outnumbered.</p>
		</div>

    </div>
</template>


<script>

	export default {
		name: "FourWaysOfThinkingArticle"
	}

</script>


<style>

</style>