<template>
    <div>

		<div class="title">
			<p class="main">Prophet Song by Paul Lynch</p>
			<div class="subheadings">
				<p class="published-date">30th September 2024</p>
			</div>
		</div>
		<div class="article">
			<p>Prophet Song by Paul Lynch, the winner of last year’s Booker Prize, straddles a strange line between impossible to put down and bearable only in small chunks. Charting the lives of a single family in a near future Ireland that has fallen into dystopia and authoritarianism, the level of suspense that builds with zero release is brilliant to read but difficult to experience.</p>

			<p>I’m very much into my fiction era now for the year which means I probably have to think differently about how to review what I read. Despite reading a lot, I’m not very good at it when it comes to fiction. “I know authors who use subtext and they’re all cowards” said Garth Marenghi and while I wouldn’t go that far, I just know I’m not as good at getting into the depths of fiction. I also have a habit of sometimes realising where the story is going or what The Twist is going to be (see Tomorrow and Tomorrow and Tomorrow below for the same issue) which can take me out of it a bit.</p>

			<p>Luckily, Prophet Song, while not being what I’d call accessible, has a very clear agenda that is almost literally spelled out. The inaccessibility comes from the writing style which is characterised by a total lack of paragraphs, and extremely long run on sentences. I actually really enjoy this style as I feel it helps to build in the reader the same tension the character is feeling but it’s not for everyone and takes some getting used to. Once you get the flow though the style becomes indispensable in telling the story.</p>

			<p>Without spoilers, the book follows Eilish Stack as she tries to keep her family safe and together in the face of a country descending rapidly into authoritarianism. We see how quickly institutions erode, or cease to be trustworthy. We see how Eilish makes small decision after small decision, each of them reasonable at the time, that mount into unbearable circumstances. A common refrain in the book is “History is a series of stories about people who didn’t leave early enough”. It feels like Lynch is making a very deliberate, almost too on the nose, point about the current refugee crises plaguing the world. I write that as someone extremely sympathetic to the plight of people fleeing war or persecution so it’s possible that someone with a less liberal attitude to asylum may find the book enlightening. I found it slightly depressing that we have to be obvious in our “They’re just like us” when I would expect people’s humanity to tell them that we should protect each other.</p>

			<p>My only real issue with the book is that I found Eilish at times frustrating to a fault. As a reader, we can often see that she is making a mistake or being unreasonable. I understand that this is kind of the point. The style and the mood all point to the tension and stress of living moment to moment and decision to decision with no time to breathe (as a character or a reader) or take stock. But that makes it all the more gutting when these decisions culminate in The Really Terrible Thing, unnamed or spoken - but felt, that the story is building too.</p>

			<p>Her inability to accept or live in the world as she finds it is ultimately Eilish’s downfall. From not believing the political situation could get truly awful, to not accepting that it is about to personally impact her, she seems blinkered. But what blinds her is not naivety or a sense that she is a privileged person who won’t be affected - it’s a simple concern for her family. That is what she cares about and that is what guides her. But she is also made to care about it by the situation she is in. When you literally cannot predict what tomorrow will bring, all you can do is handle the six inches in front of your face. That is what Eilish does, even as we as readers scream at her to see the world around her. She can’t. She is alone and afraid. This gap in understanding is really where the book shines. We can feel the tension and the stress and the unnerving reality unfolding but we can’t do anything about it. Neither can Eilish. We know what is happening. She doesn’t. But we’re both powerless.</p>

			<p>There are few shocks to be found in Prophet Song. This is not a page turner. It’s to be reflected on and thought about. It’s to inform our decisions about how we treat other people in the midst of disasters (or “The end of their world” as Eilish puts it) personal or global. It’s to demonstrate how we can all make choices in the interests of our family and our safety that turn out to be wrong. This is what Lynch is trying to say in his characterisations but also in his use of time. Long stretches pass between chapters and we are left wondering what happened in between. But of course, we know. Everyone put one foot in front of the other and now here they are at an inflection point - but can they see it and take advantage? We’re often dropped into these chapters without knowing exactly what happened before so we see the issue unfold with the characters. Can you honestly say you saw it coming? It’s not a twist or a shock but a progression. How do you separate so many shades of grey?</p>

			<p>I really loved Prophet Song and I honestly wasn’t expecting to. I like to read the Booker Prize winner each year and absolutely could not put down The Promise last year. Prophet Song is different but they share characteristics. They are both basically stories about a family’s evolution in strange times. But Prophet Song does something more. It reaches out of the page and asks you “No really. What would you do? What could you have done? What is safety anyway?” and those are difficult questions to answer. As we see more and more countries, especially in the west, begin to flirt with the kind of authoritarianism Lynch uses as his backdrop, we may have to find answers to those questions sooner than we hoped.</p>

		</div>

    </div>
</template>


<script>

	export default {
		name: "ProphetSongArticle"
	}

</script>


<style>

</style>